<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="content">
        <div class="form">
          <div class="item">
            <div class="title">岗位</div>
            <input type="text" v-model="name" class="input" placeholder="请输入岗位名称" />
          </div>
          <div class="item">
            <div class="title">备注</div>
            <input type="text" v-model="remark" class="input" placeholder="请输入备注信息" />
          </div>
        </div>
        <div class="button primary mt" @click="submit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import { isEmpty, isTel } from '@/utils';
import qs from 'qs';
export default {
  data() {
    return {
      name: '',
      remark: ''
    };
  },
  methods: {
    async submit() {
      if (isEmpty(this.name)) {
        Toast('岗位名称不可以为空!');
        return;
      }
      let { data } = await this.axios.post(
        '/ctid/tlService/addDoor',
        qs.stringify({ doorname: this.name, bz: this.remark })
      );
      if (data.flag === '0') {
        Toast('添加成功!');
        this.$router.replace('/position/detail/' + data.data.client_id);
      } else {
        Dialog({ message: data.message });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 366px;
}
.content {
  margin-top: 60px;
}
</style>